import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import WelcomeView from '../views/WelcomeView.vue'
import CastView from '../views/CastViewOnePerViewV2.vue'
import MessengerView from '../views/MessengerView.vue'

const routes = [
  {
    path: '/:party_id',
    name: 'login',
    component: LoginView,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'welcome',
    component: WelcomeView
  },
  {
    path: '/cast/:party_id',
    name: 'party_cast',
    component: CastView,
    props: true
  },
  {
    path: '/chat/:party_id',
    name: 'party_view',
    component: MessengerView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach(async (to, from) => {

// })

export default router
